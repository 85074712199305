<template>
    <div class="model-library-page">
        <div v-if="!dialogModelAuditVisible">
            <el-form size="small" :inline="true" :model="modelAuditForm" class="demo-form-inline">
                <el-form-item label="模型名称：">
                    <el-input style="width: 200px" v-model="modelAuditForm.searchVal" clearable
                              placeholder="请输入模型名称">
                    </el-input>
                </el-form-item>
                <el-form-item label="模型编号：">
                    <el-input style="width: 200px" v-model="modelAuditForm.searchValNum" clearable
                              placeholder="请输入模型编号"></el-input>
                </el-form-item>
                <el-form-item label="公司名称：">
                    <el-input style="width: 200px" v-model="modelAuditForm.companyName" clearable
                              placeholder="请输入公司名称">
                    </el-input>
                </el-form-item>
                <el-form-item label="材质名称：">
                    <el-input style="width: 200px" v-model="modelAuditForm.textureName" clearable
                              placeholder="请输入材质名称">
                    </el-input>
                </el-form-item>
                <el-form-item label="材质编号：">
                    <el-input style="width: 200px" v-model="modelAuditForm.textureNum" clearable
                              placeholder="请输入材质编号">
                    </el-input>
                </el-form-item>
                <!-- <el-form-item label="日期：">
                    <el-date-picker style="width: 360px" v-model="modelAuditForm.dateTimeArr" type="datetimerange"
                        value-format="YYYY-MM-DD HH:mm:ss" range-separator="-" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="体系：">
                    <el-cascader style="width: 180px" v-model="modelAuditForm.system" :popper-append-to-body="false"
                        :options="systemList" :props="systemProps" clearable />
                </el-form-item>
                <el-form-item label="品牌：">
                    <el-select style="width: 120px" size="small" v-model="modelAuditForm.brand" clearable
                        placeholder="请选择品牌">
                        <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="状态：" v-show="activeName == 'auditRecord'">
                    <el-select style="width: 120px" size="small" v-model="modelAuditForm.status" clearable
                               placeholder="请选择状态">
                        <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="margin-left: 20px">
                    <div class="btn" @click="handleSearch">查询</div>
                </el-form-item>
            </el-form>
            <div class="model-library-content-wrap">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="待审核" name="waitAudit">
                        <div class="waitAudit-content-wrap">
                            <ul class="content-list">
                                <li class="content-item" v-for="(waitList, index) in waitAuditDataList" :key="index">
                                    <div class="top-wrap">
                                        <span>编号：{{ index + 1 }}</span>
                                        <div>
                                            <el-button size="small" type="text" @click="handleWaitAuditBtn(waitList)">审核
                                            </el-button>
                                            <el-button v-if="waitList.modelFile != ''" size="small" type="text"
                                                       @click="download(waitList.modelFile, waitList.modelName)">下载
                                            </el-button>
                                        </div>
                                    </div>
                                    <div class=" image-wrap">
                                        <img :src="waitList.modelIcon" alt=""/>
                                    </div>
                                    <div class="bottom-wrap">
                                        <p class="class-name">分类：{{ getClassifyName(waitList.classify_map) }}</p>
                                        <p class="model-name">模型名称：{{ waitList.modelName }}</p>
                                        <!-- <p class="brand-name">品牌名称：{{ waitList.brandName }}</p> -->
                                        <p class="brand-name">企业名称：{{ waitList.e_name }}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="审核记录" name="auditRecord">
                        <div class="auditRecord-content-wrap">
                            <ul class="content-list">
                                <li class="content-item" v-for="(recordList, index) in auditRecordDataList"
                                    :key="index">
                                    <div class="top-wrap">
                                        <span>编号：{{ index + 1 }}</span>
                                        <!-- <el-button size="small" type="text" @click="handleAuditRecordBtn(recordList)">
                                            审核记录</el-button> -->
                                    </div>
                                    <div class="image-wrap">
                                        <!-- <img :src="recordList.modelUrl" alt="" /> -->
                                        <img :src="recordList.modelIcon" alt=""/>
                                    </div>
                                    <div class="reject-reason-wrap" v-if="recordList.status === -1">
                                        <p class="reject-reason">驳回原因</p>
                                        <el-tooltip class="item" effect="dark" :content="recordList.reason"
                                                    placement="top">
                                            <span class="reason-desc">{{ recordList.reason }}</span>
                                        </el-tooltip>
                                    </div>
                                    <div class="bottom-wrap">
                                        <p class="class-name">分类：{{ getClassifyName(recordList.classify_map) }}</p>
                                        <p class="model-name">模型名称：{{ recordList.modelName }}</p>
                                        <!-- <p class="brand-name">品牌名称：{{ recordList.brandName }}</p> -->
                                        <p class="brand-name">企业名称：{{ recordList.e_name }}</p>
                                    </div>
                                    <div class="prompt" :class="{ enable: recordList.status == 1 }">
                                        {{ recordList.status == 1 ? '通过' : '驳回' }}
                                        <!--                                        此处加修改分类-->
                                    </div>

                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div style="text-align: right; margin-top: 30px">
                <el-pagination v-model:currentPage="page.page" :page-sizes="[10, 15, 30, 50]" :page-size="page.pageSize"
                               layout="total, sizes, prev, pager, next, jumper" :total="page.count"
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>
        <el-dialog v-model="dialogVisible" title="审核" width="30%" center>
            <div style="text-align: center;">
                <el-radio-group v-model="radio1">
                    <el-radio label="1" size="large">通过</el-radio>
                    <el-radio label="-1" size="large">驳回</el-radio>
                    <el-radio label="3" size="large">修改分类</el-radio>
                </el-radio-group>
                <div v-if="radio1 == '-1'" style="margin-top: 20px;">
                    <el-input v-model="textarea" :rows="2" type="textarea" placeholder="请输入驳回原因"/>
                </div>
                <div v-if="radio1 == '3'" style="margin-top: 20px;">
                    <el-cascader v-model="targetClassify" :options="modelConfigList"
                                 :props="{ label: 'name', value: 'id' }"
                                 style="width: 400px;">
                    </el-cascader>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false;targetClassify=[]">取消</el-button>
                    <el-button type="primary" @click="btnOK">确定</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 模型审核弹窗 -->
        <!-- <ModelUploadCom :modelId="modelId" v-else></ModelUploadCom> -->
        <!--        <dialogFrame-->
        <!--            :show="dialogModelAuditVisible"-->
        <!--            @showDialog="showDialog"-->
        <!--            :title="'模型审核'"-->
        <!--            :footer="false"-->
        <!--            width="100%"-->
        <!--            top="0px"-->
        <!--            center-->
        <!--        >-->
        <!--            <modelAuditDialog-->
        <!--                v-if="dialogModelAuditVisible"-->
        <!--                :modelAuditData="modelAuditData"-->
        <!--                :pageName="activeName"-->
        <!--                @closeDialog="closeDialog"-->
        <!--            ></modelAuditDialog>-->
        <!--        </dialogFrame>-->
    </div>
</template>
<script>
// import dialogFrame from '@/components/common/dialog/dialog-frame.vue';
// import modelAuditDialog from './components/modelAuditDialog.vue';
// import ModelUploadCom from "./components/modelUploadCom";
// import jsonTool from '@/utils/jsonTool.js';
// import { getAttributesGetModel } from '@/common/api/model/modelUpload/index.js';
// import { getWaitAuditData, getAuditRecordData } from '@/common/api/model/modelAudit/index.js';
import {
    getWaitAuditData,
    modelClassifyAccept,
    modelsConfig_list,
    newesChange_status
} from '@/common/api/model/modelAudit/index.js';

export default {
    name: 'model',
    components: {
        // dialogFrame,
        // modelAuditDialog,
        // ModelUploadCom
    },
    data() {
        return {
            options: [],
            newClassifyFlag: false, // 是否新分类
            classifyInfo: '', // 拼接通过新分类所需字段
            targetClassify: [],//目标修改分类
            modelConfigList: [],// MX已有体系分类列表
            // 驳回文本
            textarea: '',
            // 按钮
            radio1: '1',
            dialogVisible: false,
            modelAuditForm: {
                // 模型名称
                searchVal: '',
                // 模型编号
                searchValNum: '',
                // 时间
                dataTimeArr: [],
                // 体系
                system: '',
                // 状态
                status: 0,
                // 品牌
                brand: '',
                // 公司名称
                companyName: '',
                // 材质名称
                textureName: '',
                // 材质编号
                textureNum: '',
            },
            systemProps: {
                multiple: true,
                checkStrictly: true,
                value: 'id',
                label: 'name',
                children: 'materialList'
            },
            activeName: 'waitAudit',
            // 体系列表
            systemList: [],
            // 品牌列表
            brandList: [],
            // 状态列表
            statusList: [
                {
                    id: 0,
                    name: '全部'
                },
                {
                    id: 1,
                    name: '已通过'
                },
                {
                    id: -1,
                    name: '已驳回'
                }
            ],
            //待审核页面数据
            waitAuditDataList: [],
            // 审核记录页面数据
            auditRecordDataList: [],
            // 是否显示待审核页面模型审核弹窗
            dialogModelAuditVisible: false,
            // 模型审核弹窗数据
            modelAuditData: {},
            // 模型工具url
            modelToolUrl: '',
            page: {
                page: 1,
                pageSize: 10,
                count: 0
            },
            modelId: '',
        };
    },
    watch: {
        dialogVisible() {
            if (this.dialogVisible == false) {
                this.radio1 = '1'
                this.textarea = ''
            }
        }
    },
    mounted() {
        // this.init();
        this.queryModelAuditData()
        this.getMXModelClassify()
    },
    methods: {
        getMXModelClassify() {
            modelsConfig_list().then(res => {
                let {code, classify_list} = res
                if (code == 200) {
                    this.modelConfigList = classify_list
                    let transfer = (configList) => { // 供el级联下拉框使用
                        let arr = configList
                        for (let i = 0; i < arr.length; i++) {
                            if (arr[i].children && arr[i].children.length != 0) {
                                transfer(arr[i].children)
                            } else {
                                arr[i].children = ''
                            }
                        }
                        return arr
                    }
                    this.options = transfer(this.modelConfigList)
                }
            })

        },

        getClassifyName(val) {
            let arr = []
            val.forEach(item => {
                arr.push(item.name)
            })
            return arr.join('>')
        },
        // .map((item) => item.name + '-')
        // btnOK() { // 旧 通过/驳回
        //     let dt = {
        //         id: this.modelId,
        //         status: this.radio1,
        //         reason: this.textarea
        //     }
        //     newesChange_status(dt).then(res => {
        //         let { code, msg } = res
        //         if (code == 200) {
        //             this.$message.success(msg)
        //             this.dialogVisible = false
        //             this.queryModelAuditData()
        //         }
        //     })
        // },

        btnOK() { // 新
            if (this.radio1 == 1 && this.newClassifyFlag) { //
                modelClassifyAccept({classify_ids: this.classifyInfo}).then(res => {
                    let {code} = res
                    if (code != 200) {
                        this.$message.error('新分类通过失败')
                        return
                    }
                })
            }
            let dt = {
                id: this.modelId,
                status: this.radio1,
                reason: this.textarea,
                // 取this.targetClassify最后一个
                suggestion_classify_id: this.targetClassify.at(-1)
            }
            newesChange_status(dt).then(res => {
                let {code, msg} = res
                if (code == 200) {
                    this.$message.success(msg)
                    this.dialogVisible = false
                    this.queryModelAuditData()
                }
            })
        },
        // handleClose() {
        //     this.modelId = '';
        //     this.dialogModelAuditVisible = false;
        //     this.init();
        // },
        handleClick(tab) {
            this.page.page = 1
            this.page.pageSize = 10
            this.activeName = tab.props.name;
            this.queryModelAuditData();
        },
        // showDialog(val) {
        //     this.dialogModelAuditVisible = val;
        //     this.queryModelAuditData();
        // },
        closeDialog(val) {
            this.dialogModelAuditVisible = val;
            // alert('af');
            // window.removeEventListener('message', function () {
            //     alert('删除时间');
            // });
        },
        init() {
            this.queryAttributesData();
            this.queryModelAuditData();
        },
        // // 查询体系/品牌列表数据
        // queryAttributesData() {
        //     getAttributesGetModel()
        //         .then(res => {
        //             let { code, setup_list } = res;
        //             if (code === 200) {
        //                 let newResult = jsonTool(setup_list);
        //                 this.systemList = newResult.map(item => {
        //                     return {
        //                         id: item.id,
        //                         name: item.setupName,
        //                         materialList: item.materialList.map(item => {
        //                             return {
        //                                 id: item.id,
        //                                 name: item.materialName
        //                             };
        //                         })
        //                     };
        //                 });
        //                 // console.log('体系this.systemList: ', this.systemList);
        //                 this.brandList = newResult.brandList.map(item => {
        //                     return {
        //                         id: item.bId,
        //                         name: item.bName
        //                     };
        //                 });
        //                 // console.log('this.brandList: ', this.brandList);
        //             }
        //         })
        //         .catch(err => {
        //             console.log(err);
        //         });
        // },
        // 查询待审核页面数据
        queryModelAuditData() {
            // let setupIds = [];
            // let materialIds = [];
            // if (this.modelAuditForm.system.length) {
            //     this.modelAuditForm.system.forEach(ele => {
            //         if (ele.length > 1) {
            //             materialIds.push(ele[1]);
            //         } else {
            //             setupIds.push(ele[0]);
            //         }
            //     });
            // }
            // let startTime = this.modelAuditForm.dateTimeArr ? this.modelAuditForm.dateTimeArr[0] : '';
            // let endTime = this.modelAuditForm.dateTimeArr ? this.modelAuditForm.dateTimeArr[1] : '';
            let waitParams = {
                // Name: this.modelAuditForm.searchVal,
                // StartTime: startTime,
                // EndTime: endTime,
                // BrandID: this.modelAuditForm.brand,
                // SetupID: setupIds,
                // ClassifyID: materialIds,
                // Page: this.page.page,
                // Len: this.page.pageSize

                page: this.page.page,
                limit: this.page.pageSize,
                status: '',
                search_model_name: this.modelAuditForm.searchVal,
                search_model_number: this.modelAuditForm.searchValNum,
                search_enterprise: this.modelAuditForm.companyName,
                search_material_name: this.modelAuditForm.textureName,
                search_material_number: this.modelAuditForm.textureNum,
                search_status: '',
            };
            let recordParams = {
                page: this.page.page,
                limit: this.page.pageSize,
                status: 1,
                search_model_name: this.modelAuditForm.searchVal,
                search_model_number: this.modelAuditForm.searchValNum,
                search_enterprise: this.modelAuditForm.companyName,
                search_material_name: this.modelAuditForm.textureName,
                search_material_number: this.modelAuditForm.textureNum,
                search_status: this.modelAuditForm.status == 0 ? 2000 : this.modelAuditForm.status,
            };
            let params = this.activeName == 'waitAudit' ? waitParams : recordParams;
            // let submitFun = this.activeName == 'waitAudit' ? getWaitAuditData : getAuditRecordData;
            // submitFun(params).then(res => {
            getWaitAuditData(params).then(res => {
                let {code, result} = res
                if (code == 200) {
                    if (this.activeName == 'waitAudit') {
                        this.waitAuditDataList = result.data;
                    } else {
                        this.auditRecordDataList = result.data;
                    }
                    this.page.count = result.total;
                }
                // let { code } = res;
                // if (code === 200) {
                //     let modelListData = jsonTool(res.ModelsAudit);
                //     let newModelListData = modelListData.map(item => {
                //         return {
                //             id: item.modelId,
                //             brandId: item.brandId,
                //             modelUrl: item.modelUrl,
                //             modelName: item.modelName,
                //             number: item.number,
                //             brandName: item.brandName,
                //             reason: item.reason,
                //             system: item.setupId,
                //             classification: item.classifyId,
                //             material: item.sonClassifyId,
                //             status: item.status
                //         };
                //     });
                //     if (this.activeName == 'waitAudit') {
                //         this.waitAuditDataList = newModelListData;
                //     } else {
                //         this.auditRecordDataList = newModelListData;
                //     }
                //     this.page.count = res.Count;
                //     this.modelToolUrl = res.url;
                // }
            })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理查询按钮
        handleSearch() {
            this.queryModelAuditData();
        },
        // 处理待审核页面审核按钮
        handleWaitAuditBtn(data) {
            // let { id, number, system, classification, material, brandId, modelName } = data;
            // this.modelAuditData = {
            //     id,
            //     system,
            //     classification,
            //     material,
            //     brandId,
            //     number,
            //     modelName,
            //     canvasUrl: this.modelToolUrl
            // };
            this.modelId = data.id;
            // this.dialogModelAuditVisible = true;
            // 过滤出data的classify_map里每一项的id
            this.classifyInfo = data.classify_map.map(item => {
                return item.id;
            }).join('|');
            // 如果data的classify_map里有一项的status不是1，就返回true
            this.newClassifyFlag = data.classify_map.some(item => {
                return item.status != 1;
            });
            this.dialogVisible = true
        },
        // 下载模型
        download(url, fileName) {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = "blob"; // 通过文件下载url拿到对应的blob对象
            xhr.onload = () => {
                if (xhr.status === 200) {
                    let link = document.createElement("a");
                    // let body = document.querySelector("body");
                    link.href = window.URL.createObjectURL(xhr.response);
                    link.download = fileName + '.' + url.split('.')[url.split('.').length - 1];
                    link.click();
                    // body.removeChild(link);
                    window.URL.revokeObjectURL(link.href);
                }
            };
            xhr.send();
        },
        // 处理审核记录页面审核记录按钮
        handleAuditRecordBtn(data) {
            let {id, brandId} = data;
            this.modelAuditData = {
                id,
                brandId,
                canvasUrl: this.modelToolUrl
            };
            this.dialogModelAuditVisible = true;
        },
        // 处理分页
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.queryModelAuditData();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.queryModelAuditData();
        }
    }
};
</script>
<style scoped>
ul,
p {
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

.model-library-page {
    box-sizing: border-box;
    padding: 15px;
    background-color: #fff;
}

.model-library-page .btn {
    display: inline-block;
    width: 90px;
    height: 35px;
    background-color: rgba(85, 85, 85, 1);
    border-radius: 2px;
    color: #ffffff;
    font-size: 13px;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
}

.model-library-page .btn:first-child {
    margin-right: 20px;
}

.model-library-page .btn:hover {
    color: rgb(254, 154, 35);
}

.model-library-content-wrap {
    margin-top: 10px;
}

.content-action-left {
    text-align: end;
}

.auditRecord-content-wrap,
.waitAudit-content-wrap {
    margin-top: 20px;
}

.auditRecord-content-wrap .content-list,
.waitAudit-content-wrap .content-list {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #7f7f7f;
}

.content-list .content-item {
    position: relative;
    box-sizing: border-box;
    width: 265px;
    height: 400px;
    margin: 0 21.5px 20px;
    padding: 5px 10px;
    background-color: #f2f2f2;
    border-radius: 5px;
}

.content-list .content-item:hover .reject-reason-wrap {
    display: block;
}

.top-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    padding: 0 5px;
}

.top-wrap,
.top-wrap :deep(.el-button) {
    font-size: 14px;
}

.image-wrap {
    position: relative;
    width: 235px;
    height: 215px;
    margin: 5px auto;
}

.image-wrap img {
    width: 100%;
    height: 100%;
}

.prompt {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 145px;
    height: 25px;
    margin: auto;
    border: 1px solid rgb(217, 0, 27);
    line-height: 25px;
    text-align: center;
    color: rgb(217, 0, 27);
}

.enable {
    border: 1px solid #04f21c !important;
    color: #04f21c !important;
}

.bottom-wrap {
    box-sizing: border-box;
    margin-top: 15px;
    padding-left: 5px;
    font-size: 14px;
}

.bottom-wrap .model-name,
.bottom-wrap .brand-name,
.reject-reason-wrap .reason-desc {
    margin-top: 15px;
    overflow: hidden;
    word-break: break-all;
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
}

.bottom-wrap .class-name {
    margin-top: 15px;
    overflow: hidden;
    word-break: break-all;
}

.reject-reason-wrap {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 70px;
    width: 237px;
    height: 70px;
    margin: 5px auto;
    border-radius: 2px;
    background-color: #fff;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.reject-reason-wrap .reject-reason {
    color: #02a7f0;
    margin-top: 10px;
}

.reject-reason-wrap .reason-desc {
    box-sizing: border-box;
    display: block;
    padding: 0 5px;
}
</style>
