import $http from '../../../../utils/http.js';
import $newHttp from '../../../../utils/newHttp.js';
/**
 * 查询待审核模型数据
 * 审核列表
 * @param data
 */
// export function getWaitAuditData(data) {
//     return $http.get('/approve/not_approved', data, 'loadingDiv');
// }
export function getWaitAuditData(data) {
    return $http.get('/modelAudit_list', data, 'loadingDiv');
}

/**
 * 查询审核记录模型数据
 * @param data
 */
// export function getAuditRecordData(data) {
//     return $http.get('/approve/reviewed', data, 'loadingDiv');
// }

/**
 * 通过和驳回
 * @param data
 */
export function newesChange_status(data) {
    return $http.post('/newesChange_status', data, 'loadingDiv');
}


/**
 * 审核操作
 * @param data
 */
export function modelAuditAction(data) {
    return $http.post('/approve/examine', data, 'loadingDiv');
}

/**
 * 操作记录
 * @param data
 */
export function auditOperationRecordsData(data) {
    return $http.get('/approve/get_audit_record', data, 'loadingDiv');
}

/**
 * 获取模型分类
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function getModelClass(data) {
    return $http.get('get_model_class', data, 'aa');
}

export function getmodelsMappingList(data) {
    return $http.get('/approve/mapping_list', data, 'loadingDiv');
}

export function getModelDetail(data) {
    return $http.get('/approve/details', data, 'loadingDiv');
}
export function getMappingauditList(data) {
    return $http.get('/mappingaudit_list', data, 'loadingDiv');
}
export function mappingchangeStatus(data) {
    return $http.post('/mappingchange_status', data);
}

// 拉取 MX已有模型体系(分类)
export function modelsConfig_list(data) {
    return $newHttp.get("/goapi/model/admin_ClassifyAll", data, 'loadingDiv');
}

// 拉取 MX已有材质
export function texturesConfig_list(data) {
    return $newHttp.get("/goapi/mapping/admin_ClassifyAll", data, 'loadingDiv', 'false');
}

// 模型 - 通过该分类
export function modelClassifyAccept(data) {
    return $newHttp.post("/goapi/model/classify_Accept", data, 'loadingDiv');
}

// 材质贴图 - 通过该分类
export function mappingClassifyAccept(data) {
    return $newHttp.post("/goapi/mapping/classify_Accept", data, 'loadingDiv');
}
